<template>
  <section>

    <div class="resume-header">
      <h1>{{$t('Nav.Resume')}}</h1>
      <div class="full-resume">
        <span v-if="$i18n.locale === 'fr'">plus d'info :</span>
        <span v-else>
          more info:
        </span>
        <a href="./files/cv_fr.pdf">
          <unicon name="file-download-alt"/>
          <span v-if="$i18n.locale === 'fr'"></span>
          <span v-else>(in french)</span>
        </a>
      </div>
    </div>
    <div id="resumeContent">

      <hr class="spacer"/>

      <h1 class = "sub-header"> Cursus </h1>
      <div class="resume-list">
        <ResumeLine
          years="2020--2023"
          :degree="$i18n.locale === 'fr' ? 'Doctorant' : 'PhD Student'"
          university="Universtié Grenoble Alpes"
          city="Grenoble (France)"
          classes=""/>
        <ResumeLine
          years="2019--2020"
          :degree="$i18n.locale === 'fr' ? 'M2 Interaction Humain Machine' : 'Second year computer science master'"
          university="Universtié Paris Sud"
          city="Saclay (France)"
          classes="HCI, Virtual Reality, Augmented Reality, UI Advanced Programming, Tangible, Groupware, InfoVis, Creative Design."/>
        <ResumeLine
          years="2019--2020"
          :degree="$i18n.locale === 'fr' ? 'Magistère 3A' : 'Magistère 3rd year'"
          university="ENS de Rennes"
          city="Rennes (France)"
          classes=""/>
        <ResumeLine
          years="2018--2019"
          degree="Prélab"
          university="ENS de Rennes"
          city="Rennes (France)"
          :classes="$i18n.locale === 'fr' ? 'Pendant leur Prélab, les étudiants de l\'ENS Rennes doivent effectuer une année de stage à l\'étranger. J\'ai effectué le mien à l\'Université de Toronto au sein du DGP Lab.' : 'During their Prélab, students from ENS Rennes have to do one year of internship outside of France. I have done mine at the University of Toronto in the DGP Lab.'"/>
        <ResumeLine
          years="Feb--Jun 2018"
          :degree="$i18n.locale === 'fr' ? 'Erasmus' : 'Student exchange'"
          university="EPFL"
          :city="$i18n.locale === 'fr' ? 'Lausanne (Suisse)' : 'Lausanne (Switzerland)'"
          classes="Human Computer Interaction, Enterprise and Service Oriented Architecture, Artificial Neural Network, Optimization for Machine Learning"/>
        <ResumeLine
          years="2017--2018"
          :degree="$i18n.locale === 'fr' ? 'M1 informatique' : 'First year computer science master'"
          university="ENS de Rennes"
          city="Rennes (France)"
          classes="Machine Learning, Operating System, Game Theory, Compiler, Bioinformatics, Software Formal Analysis and Design."/>
        <ResumeLine
          years="2016--2017"
          :degree="$i18n.locale === 'fr' ? 'Licence informatique' : 'Computer science bachelor'"
          university="ENS de Rennes"
          city="Rennes (France)"
          classes="Formal languages and computability, Logic, Algorithms, Concurent Algorithms, Programming (Python, C, C++, Scala, OCaml, LISP), Architecture, Cryptography, Linear optimisation, Lebesgue's integral, Security, Statistics"/>
        <ResumeLine
          years="2013--2016"
          :degree="$i18n.locale === 'fr' ? 'CPGE' : 'Prepatory program (classes préparatoires) for French Grandes Écoles'"
          university="Lycée Claude Fauriel"
          city="Saint Étienne (France)"
          classes=""/>
      </div>

      <hr class="spacer"/>

      <h1 class = "sub-header" v-if="$i18n.locale === 'fr'"> Expériences professionnelles </h1>
      <h1 class = "sub-header" v-else> Work Experiences </h1>
      <div class="resume-list">
        <ResumeLine
          years="Mar--Jun 2020"
          degree="Research Internship"
          university="IIHM Team -- LIG"
          city="Grenoble (France)"
          classes="3D Selection in Augmented Reality using eye gaze and microgesutres. Supervisor: Alix Goguey, Laurence Nigay"
          fileUrl="/files/M2Internship.pdf"/>
        <ResumeLine
          years="Mar--Jun 2020"
          degree="Research Internship"
          university="IIHM Team -- LIG"
          city="Grenoble (France)"
          classes="3D Selection in Augmented Reality using eye gaze and microgesutres. Supervisor: Alix Goguey, Laurence Nigay"
          fileUrl="/files/M2Internship.pdf"/>
        <ResumeLine
          years="2018--2019"
          degree="Research Internship"
          university="DGP Lab -- University of Toronto"
          city="Toronto (Canada)"
          classes="Research internship at the DGP Lab on improving visualization literacy in early grades. Supervisor: Fanny Chevalier"/>
        <ResumeLine
          years="Feb--Jun 2018"
          degree="Semester Project"
          university="HCI Group -- EPFL"
          city="Lausanne (Switzerland)"
          classes="Research project at EPFL on Intra-Personal time series data analysis. Supervisor: Pearl Pu."/>
        <ResumeLine
          years="Sep--Dec 2017"
          degree="Semester Project"
          university="CAIRN Team -- IRISA"
          city="Rennes (France)"
          classes="Research project on polynomial scheduling for automatic parallelization. Supervisor: Tomofumi Yuki"/>
        <ResumeLine
          years="Summer 2017"
          degree="Research Internship"
          university="IIHM Team -- LIG"
          city="Grenoble (France)"
          classes="Research internship on spatial multiplexing on a smartwatch to interact with large screen. Supervisor: Yann Laurillau, Laurence Nigay"
          fileUrl="/files/L3Internship.pdf"
        />
      </div>

      <hr class="spacer"/>

      <h1 class = "sub-header"> Computer Skill </h1>
      <div class="resume-list">
        <ResumeLine
          years="Intermediate"
          degree="C, C++, Java, Git, SQL, Ocaml"/>
        <ResumeLine
          years="Confirmed"
          degree="HTML, CSS, JavaScript, Vue.js, TypeScript, Python, LaTeX"/>
      </div>

      <hr class="spacer"/>

      <h1 class = "sub-header"> Languages </h1>
      <div class="resume-list">
        <ResumeLine
          years="French"
          degree="Mother tongue"/>
        <ResumeLine
          years="English"
          degree="Fluent"/>
      </div>
    </div>
  </section>
</template>

<script>
import ResumeLine from '@/components/Lines/ResumeLine'
export default {
    name: 'Resume',
  components: {ResumeLine}
}
</script>

<style scoped>
.resume-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.full-resume {
  display: flex;
  align-items: center;
}

.full-resume > span {
  font-size: smaller;
  display: none;
}

.full-resume a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-resume a span {
  font-weight: lighter;
  font-size: x-small;
}

sub-header {
  font-size: 150%;
}

@media (min-width: 768px) {
  .resume-list {
    margin-left: 60px;
  }

  .sub-header {
    font-size: 200%;
  }

  .full-resume > span {
    display: initial;
  }
}
</style>
