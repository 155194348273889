<template>
  <router-link class="round-img-container" to="/embroidery">
    <img src="@/assets/perso/3.jpeg" class="round-img bottom" >
    <img src="@/assets/id2.jpg" class="round-img top" v-if="displayAlt">
    <img src="@/assets/id.jpg" class="round-img top" v-else>
  </router-link>
</template>

<script>
export default {
  name: 'Round_Picture',
  data() {
    return {
      displayAlt: false
    }
  },
  mounted () {
    let n = 1000
    const f = () => {
        setTimeout(() => {
          this.displayAlt = true
          setTimeout(() => {
            this.displayAlt = false
            f()
          }, n)
      }, n * 15)
    }
    f()
  }
}
</script>

<style scoped>

a {
  position: relative;
  display: block;
}

.dark img {
  filter: brightness(0.6);
}

.bottom {
  position: absolute;
  top: 0;
  left: 0;
  object-position: 0 100%;
}

.top {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -o-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
  object-fit: cover;
}

.top:hover {
  opacity: 0.1;
}


.round-img-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  overflow: hidden;
}
.round-img {
  object-fit: cover;
  object-position: right center;
  transition: object-position .25s;
}

.round-img:hover {
  object-position: center
}
</style>
