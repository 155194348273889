import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Resume from '@/views/Resume'
import Embroidery from '@/views/Embroidery'
import ResearchRelated from '@/views/ResearchRelated'
import Footer from '@/components/Footer'
import Teaching from '@/views/Teaching'
import Publications from '@/views/Publications'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      footer: Footer
    }
  },
  {
    path: '/resume',
    name: 'Resume',
    components: {
      default: Resume,
      footer: Footer
    }
  },
  {
    path: '/embroidery',
    name: 'Embroidery',
    component: Embroidery
  },
  {
    path: '/teaching',
    name: 'Teaching',
    components: {
      default: Teaching,
      footer: Footer
    }
  },
  {
    path: '/research_related',
    name: 'ResearchRelated',
    components: {
      default: ResearchRelated,
      footer: Footer
    }
  },
  {
    path: '/Publications',
    name: 'Publications',
    components: {
      default: Publications,
      footer: Footer
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
