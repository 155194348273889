<template>
  <section>
    <h1>{{$t('Nav.Teaching')}}</h1>
    <hr class="spacer" />

    <h1 class = "sub-header">2023 - 2024</h1>
    <h3>Interaction Humain Machine Avancée <span class="smaller-text">(KARI9M15)</span> <a href="/files/collecticiel.pdf"><unicon name="file-download-alt"/></a></h3>
    <span>Lecture (6h) and practical courses (6h) at Polytech - Master 2</span>

    <h1 class = "sub-header">2022 - 2023</h1>
    <h3>Interactive design<span class="smaller-text">(R4.05)</span></h3>
    <span>Lecture (8h) and Practical courses (12h) at IUT MMI - 2 year</span>
    <h3>Interaction Humain Machine Avancée <span class="smaller-text">(KARI9M15)</span> <a href="/files/collecticiel.pdf"><unicon name="file-download-alt"/></a></h3>
    <span>Lecture (6h) and practical courses (6h) at Polytech - Master 2</span>
    <h3>Front-end development<span class="smaller-text">(R3.12)</span></h3>
    <span>Practical courses (44h) at IUT MMI - 2 year</span>


    <h1 class = "sub-header">2021 - 2022</h1>

    <h3>Collecticiel <span class="smaller-text">(GBGIXU07)</span></h3>
    <span>Lecture (6h) at Université Grenoble Alpes - Master 2 GI</span>

    <h3>Interaction Humain Machine Avancée <span class="smaller-text">(KARI9M15)</span></h3>
    <span>Lecture (6h) and practical courses (6h) at Polytech - Master 2</span>

    <h3>Multimodalité et mobilité <span class="smaller-text">(KARI9M17)</span></h3>
    <span>Lecture (14h) and practical courses (14h) at Polytech - Master 2</span>

    <h3>Multimodalité et mobilité <span class="smaller-text">(GBGI9U06)</span></h3>
    <span>Lecture (3h) and practical courses (12h) at Université Grenoble Alpes - Master 2 GI</span>



    <h1 class = "sub-header">2020 - 2021</h1>

    <h3>Modèles De Calcul <span class="smaller-text">(GBIN6U02)</span></h3>
    <span>Practical courses (12h) at Université Grenoble Alpes - License 3</span>

    <h3>Interaction Humain Machine Avancée <span class="smaller-text">(KARI9M15)</span></h3>
    <span>Lecture (6h) and practical courses (6h) at Polytech - Master 2</span>

    <h3>Multimodalité et mobilité <span class="smaller-text">(KARI9M17)</span></h3>
    <span>Lecture (8h) and practical courses (14h) at Polytech - Master 2</span>

    <h3>Multimodalité et mobilité <span class="smaller-text">(GBGI9U06)</span></h3>
    <span>Lecture (3h) and practical courses (12h) at Université Grenoble Alpes - Master 2 GI</span>
  </section>
</template>

<script>
export default {
    name: 'Teaching'
}
</script>

<style scoped>
section > h3, section > span {
  font-size: smaller;
}

.smaller-text {
  font-weight: normal;
  font-size: x-small;
}

@media (min-width: 768px) {
  section > h3, section > span {
    font-size: initial;
    margin-left: 70px;
  }

  .smaller-text {
    font-size: small;
  }
}

h3 > span {
  margin-left: 10px;
}

.unicon {
  position: relative;
  top: 3px;
}
</style>
