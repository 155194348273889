<template>
  <nav>
    <span>
      <h1 @click="$router.push('/')">Adrien <br> Chaffangeon Caillet</h1>
      <h2>{{$t('Nav.subtitle')}}</h2>
    </span>
    <span class="flex-center">
      <span class="menu" :class="displayMenu ? '' : 'hide'">
        <router-link to="/" @click.native="displayMenu = !displayMenu">{{$t('Nav.Home')}}</router-link>
        <router-link to="/resume" @click.native="displayMenu = !displayMenu">{{$t('Nav.Resume')}}</router-link>
        <router-link to="/publications" @click.native="displayMenu = !displayMenu">{{$t('Nav.Publications')}}</router-link>
        <router-link to="/research_related" @click.native="displayMenu = !displayMenu">{{$t('Nav.ResearchRelated')}}</router-link>
        <router-link to="/teaching" @click.native="displayMenu = !displayMenu">{{$t('Nav.Teaching')}}</router-link>
        <router-link to="/embroidery" @click.native="displayMenu = !displayMenu">Hobbies</router-link>
      </span>
      <span @click="displayMenu = !displayMenu" class="menu-hamb">
        <unicon name="bars"/>
      </span>
      <ThemeButton/>
    </span>
  </nav>
</template>

<script>
import ThemeButton from '@/components/ThemeButton'
export default {
  name: 'Nav',
  components: { ThemeButton },
  data () {
    return {
      displayMenu: false
    }
  }
}
</script>

<style scoped>
.flex-center {
  flex-direction: row;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 15px;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
}

h1, h2 {
  margin: 0;
  padding: 0;
}

h1 {
  cursor: pointer;
}

h2 {
  font-size: smaller;
}

.menu {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  background-color: var(--foreground);
  box-shadow: var(--light-shadow);
  padding: 10px;
  border-radius: 10px;
  right: 30px;
  top: 72px;
  z-index: 20;
}

.menu.hide {
  display: none;
}

.menu a {
  margin: 0 20px;
  font-size: large;
  font-weight: 500;
}

.menu a.router-link-exact-active {
  color: var(--accent);
  fill: var(--accent);
}

.menu-hamb {
  margin-right: 10px;
  position: relative;
  top: 6px;
}

@media (min-width: 768px) {
  .menu.hide {
    display: flex;
  }

  .menu {
    flex-direction: row;
    position: initial;
    background-color: transparent;
    box-shadow: none;
    padding: 10px;
  }

  .menu-hamb {
    display: none;
  }
}

@media (min-width: 1250px) {
  h1 {
    font-size: xx-large;
  }
}
</style>
