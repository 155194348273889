<template>
  <div class="publication-line">
    <img :src="image" :class="(inverColor) ? 'invert' :''">
    <div>
      <p>{{authors}}</p>
      <a :href="paperUrl" target="_blank">
        {{title}}
      </a>
      <p><i>{{conference}}</i></p>
      <a v-if="fileUrl" :href="fileUrl" target="_blank"><unicon name="file-alt"/></a>
      <a v-if="videoUrl" :href="videoUrl" target="_blank"><unicon name="video"/></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicationLine',
  props: ['title', 'authors', 'conference', 'image', 'paperUrl', 'fileUrl', 'videoUrl', 'inverColor']
}
</script>

<style scoped>
.publication-line {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  margin: 20px 0;
}

h3 {
  text-align: left;
  font-weight: normal;
}

img {
  display: none;
}

.dark img.invert {
  filter: invert(1);
}

@media (min-width: 768px) {
  .publication-line {
    grid-template-columns: 120px auto;
    align-items: center;
  }

  img {
    display: initial;
    justify-self: end;
    width: 120px;
    height: auto;
  }
}
</style>
