import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dark: (localStorage.getItem('chafDark') === 'true') ? true : false
  },
  mutations: {
    toggleDark (state) {
      state.dark = !state.dark
      localStorage.setItem('chafDark', state.dark)
    }
  },
  actions: {
  },
  modules: {
  }
})
