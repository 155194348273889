<template>
    <section>
      <h1>Hobbies</h1>
      <p>I like to sew, embroider and build stuff.</p>
      <div id="picture-gallery">
        <div v-for="picture of pictures" :key="picture.img">
          <img :src="require(`@/assets/perso/${picture.img}`)"/>
          <p>{{picture.description}}</p>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'Embroidery',
    data() {
      return {
        pictures: [
          {
            img: "15.jpeg",
            description: ""
          },
          {
            img: "10.jpeg",
            description: ""
          },
          {
            img: "12.jpeg",
            description: ""
          },
          {
            img: "11.jpeg",
            description: ""
          },
          {
            img: "9.jpeg",
            description: ""
          },
          {
            img: "8.jpeg",
            description: ""
          },
          {
            img: "6.jpeg",
            description: ""
          },
          {
            img: "5.jpeg",
            description: ""
          },
          {
            img: "16.jpeg",
            description: ""
          },
          {
            img: "4.jpeg",
            description: ""
          },
          {
            img: "13.jpeg",
            description: ""
          },
          {
            img: "14.jpeg",
            description: ""
          },
          {
            img: "3.jpeg",
            description: ""
          },
          {
            img: "2.jpeg",
            description: ""
          },
          {
            img: "1.jpeg",
            description: ""
          },
          {
            img: "0.jpeg",
            description: ""
          }
        ]
      }
    }
}
</script>

<style scoped>

#picture-gallery {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

#picture-gallery div {
  position: relative;
}

#picture-gallery img {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
}

#picture-gallery #roses-img {
  object-position: 0% 100%;
}

#picture-gallery img.inline-logo {
  height: 1.5em;
  width: auto;
  filter: invert();
  display: inline;
  object-fit: contain;
  position: relative;
  top: 0.4em;
  left: 5px;
}

#picture-gallery p {
  position: relative;
  text-align: center;
  vertical-align: middle;
}
</style>
