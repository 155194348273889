<template>
    <section>
      <h1 class = "sub-header">Organizing Committee (3)</h1>
      <div class="row">
        <img class="invert" src="@/assets/icons/tei-logo.png">
        <span>Webmaster at <a href="https://tei.acm.org/2025/">TEI'25</a></span>
      </div>
      <div class="row">
        <img class="cover" src="@/assets/icons/ihm24.png">
        <span>Associate chair  at <a href="https://ihm2024.afihm.org">IHM'24</a></span>
      </div>
      <div class="row">
        <img class="cover" src="@/assets/icons/ihm23.png">
        <span>Session chair at <a href="https://ihm2023.afihm.org">IHM'23</a></span>
      </div>
      <h1 class = "sub-header">Reviews (3)</h1>
      <div class="row">
        <span class="date">2024</span>
        <span>1 CHI'24 Full paper, 2 CHI'24 Late-Breaking Work, 1 EICS'24 Full paper, 1 TOCHI paper, 1 DIS'24 Full paper </span>
      </div>
      <div class="row">
        <span class="date">2023</span>
        <span>2 CHI'23 Late-Breaking Work, 2 Interact'23 Full paper, 1 EICS'23 Full paper, 1 ISMAR' 23 Journal Paper </span>
      </div>
      <div class="row">
        <span class="date">2022</span>
        <span>1 IHM'22 Articles Scientifiques, 1 CHI'22 Late-Breaking Work  </span>
      </div>

      <h1 class = "sub-header">Public Talks (5)</h1>
      <div class="row">
        <img src="@/assets/icons/chi23.png">
        <span>µGlyph: a Microgesture Notation at <a href="https://chi2023.acm.org">CHI'23</a></span>
      </div>
      <div class="row">
        <img src="@/assets/icons/lig.png" class="smaller">
        <span>PhD Days at <a href="https://liglab.fr">LIG</a></span>
        <div class="reward">
          <span>Best presentation award</span>
          <unicon name="trophy"/>
        </div>
      </div>
      <div class="row">
        <img src="@/assets/icons/ihm22.png">
        <span>µGlyph: a Graphical Notation to Describe Microgestures at <a href="https://ihm2022.afihm.org">IHM'22</a></span>
      </div>
      <div class="row">
        <img src="@/assets/icons/pos22.png" class="smaller">
        <span>Petite Histoire de l’Interaction Humain-Machine at <a href="https://pintofscience.fr/event/petite-histoire-de-linteraction-humain-machine">Pint Of Science'22</a></span>
        <div class="reward">
          <a href="/pintOfScience"><unicon name="file-alt"/></a>
        </div>
      </div>
      <div class="row">
        <img src="@/assets/icons/ihm20.png">
        <span>PhD presentation at <a href="https://ihm2020.afihm.org">doctorale IHM'20-21</a></span>
      </div>

      <h1 class = "sub-header">Students (4)</h1>
      <div class="row">
        <div class="date">2023 <br> <span class="smaller-text">May - June</span></div>
        <div>Pedro Pablo Vega Fernandez(L2, Prépa INP), <i>Fabrication de systèmes de captation pour les microgestes</i>, co-supervised with <a href="http://aurelienconil.fr">Aurélien Conil</a></div>
      </div>
      <div class="row">
        <div class="date">2022 <br> <span class="smaller-text">Feb. - July</span></div>
        <div> <a href="https://vincent-lambert.gitlab.io/website/">Vincent Lambert</a> (3A ENSIMAG), <i>Découvrabilité des microgestes en Réalité Augmentée</i>, co-supervised with <a href="http://www.alixgoguey.fr/">Alix Goguey</a> and <a href="http://iihm.imag.fr/nigay/">Laurence Nigay</a></div>
      </div>
      <div class="row">
        <div class="date">2022 <br> <span class="smaller-text">June - July</span></div>
        <span> Carla Countant (L2, Prépa INP), <i>Extraction des définitions et illustrations de microgestes dans la littérature et traduction de ces microgestes avec µGlyphe.</i></span>
      </div>
      <div class="row">
        <div class="date">2021 <br> <span class="smaller-text">Feb. - July</span></div>
        <span> Matthieu Gillet (M2, ENS de Rennes), <i>Saisie de Texte au Clavier pour Écran Sphérique Manipulable à Perspective Corrigée</i>,co-supervised with <a href="http://iihm.imag.fr/fberard/">François Bérard</a></span>
      </div>

      <hr class="spacer" />
      <h1>Projects during my Master degree</h1>
      <h3>The Interactive Turing Machine</h3>
      <p>
        The Interactive Turing Machine is a web application for designing and simulating Turing machines. It was designed to be easy to use (no textual coding required) and playful (colorful graphics, animations, etc). This project was done with Camille Gobert.
        <a href="https://github.com/Daru13/interactive-turing-machine">
          <unicon name="github-alt"/>
        </a>
        <a href="https://daru13.github.io/interactive-turing-machine/">
          <unicon name="globe"/>
        </a>
      </p>

      <h3>Virtual Fish</h3>
      <p>
        The Virtual Fish project is a swimming pool with virtual fish projected in it. Using different tangible objects, users can add obstacles to the fish, feed them or add a new fish. This project was done with Antonin Cheymol.
        <a href="https://youtu.be/7M2UIsquKR0">
          <unicon name="video"/>
        </a>
      </p>

      <h3>Collaborative Illustrator</h3>
      <p>
        The Collaborative Illustrator is a simplified illustrator allowing collaboration between peers with WebRTC. This project was done with Antonin Cheymol.
        <a href="https://github.com/AChaffangeon/collaborative-illustrator">
          <unicon name="github-alt"/>
        </a>
        <a href="https://achaffangeon.github.io/collaborative-illustrator/">
          <unicon name="globe"/>
        </a>
      </p>

      <h3>Semantic Web Visualization</h3>
      <p>
        This project is a data visualisation class project targetting developers working with the semantic web. It is our attempt to visualise results of SPARQL queries (e.g. from dpbedia), which usually come in the form of long tables containing lots of URIs (though we actually focus on URLs, not all kinds of URIs). This project was done with Camille Gobert.
        <a href="https://github.com/Daru13/semantic-web-visualisation">
          <unicon name="github-alt"/>
        </a>
        <a href="https://daru13.github.io/semantic-web-visualisation/">
          <unicon name="globe"/>
        </a>
      </p>

      <h3>Vibrating Suspenders</h3>
      <p>
        This work is an add-on to the live coding movement interactions environment: <a href="https://codajs.netlify.app/">coda.js</a>. I built suspenders with 6 vibrators (1 on each collar, 1 on each side of the belly and 1 on each shoulder blade). Live codders can control those vibrators inside of coda.js to provide haptic feedback.
        <a href="./fig/suspenders.png">
          <unicon name="image"/>
        </a>
      </p>
    </section>
</template>

<script>
export default {
    name: 'ResearchRelated'
}
</script>

<style scoped>
.unicon {
  position: relative;
}

h3 {
  text-align: left;
}

.spacer {
  margin: 80px;
}

section > p {
  margin-left: 110px;
}

.row {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  align-items: center;
  position: relative;
  margin-top: 10px;
}

.row img {
  display: none;
}

.row img.invert {
  filter: invert(1);
}

.dark .row img.invert {
  filter: invert(0);
}

.row .reward {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.reward span {
  display: none;
}

@media (min-width: 768px) {
  .row {
    grid-template-columns: 100px auto;
  }

  .row img {
    display: initial;
    object-fit: contain;
    width: 60px;
    height: 30px;
    justify-self: center;
  }

  .row img.cover {
    object-fit: cover;
  }

  .row img.smaller {
    width: 50px;
  }

  .reward span {
    display: initial;
  }
}

.date {
  text-align: center;
}

.date .smaller-text {
  font-size: x-small;
}
</style>
