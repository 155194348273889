import Vue from 'vue'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'

import {
  uniFileDownloadAlt,
  uniSun,
  uniMoon,
  uniGithubAlt,
  uniVideo,
  uniGlobe,
  uniImage,
  uniLinkedinAlt,
  uniFileAlt,
  uniGraduationCap,
  uniTrophy,
  uniBars, uniEnvelopeAlt
} from 'vue-unicons/dist/icons'

Unicon.add([uniFileDownloadAlt,
  uniSun,
  uniMoon,
  uniGithubAlt,
  uniVideo,
  uniGlobe,
  uniImage,
  uniLinkedinAlt,
  uniFileAlt,
  uniGraduationCap,
  uniTrophy,
  uniBars,
  uniEnvelopeAlt,
])

Vue.use(Unicon, {
  height: 25,
  width: 25
})
