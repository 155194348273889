<template>
  <div id="app" :class="(dark) ? 'dark' : ''">
    <Nav/>
    <router-view/>
    <router-view name="footer"/>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import { mapState } from 'vuex'
export default {
  components: { Nav },
  computed: {
    ...mapState(['dark'])
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Krub:wght@400;700&display=swap');

body {
  padding: 0;
  margin: 0;
}

#app {
  --blue: #008ef7;
  --red: #ca1b52;
  --error: var(--red);
  background: var(--background);
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  transition: background .5s ease;
  --invert: 0;
  width: 100%;
  overflow-x: hidden;
}

#app:not(.dark) {
  --background: #fafafa;
  --foreground: #fff;
  --foreground-transparent: rgba(255,255,255,.9);
  --foreground-blur: rgba(255,255,255,.6);
  --fake-blur: #e6e8eeeb;
  --headings: #d7d7d7;
  --text: #2c3e50;
  --textlight: rgba(44, 62, 80, 0.51);
  --accent: var(--blue);
  --complement: #fff;
  --light-shadow: rgba(0, 0, 0, .05) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .05) 0px 0px 5px, rgba(0, 0, 0, .1) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(0, 142, 247, .1);
  --gradient: linear-gradient(45deg, var(--accent), #4d02b1);
  --lightgradient: linear-gradient(45deg, #e1f2ff,#f6ebfb);
  --background-alt: #e8e8e8;
  --foreground-alt: var(--foreground);
  --invert: 0;
  --pop-up-background: #fffffff5;
}

#app.dark {
  --background: #0e1117;
  --foreground: #1d222d;
  --foreground-transparent: rgba(29,34,45,.5);
  --foreground-blur: rgba(29,34,45,.5);
  --fake-blur: #e6e8eeeb;
  --headings: #202231;
  --text: #dae2ff;
  --textlight: rgba(218, 226, 255, 0.6);
  --accent: var(--blue);
  --complement: #fff;
  --blur-overlay: rgba(14, 17, 23, .5);
  --light-shadow: rgba(0, 0, 0, .1) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .1) 0px 0px 2px, rgba(0, 0, 0, .3) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(202, 49, 112, 0.3);
  --gradient: linear-gradient(45deg, var(--accent),#4d02b1);
  --lightgradient: linear-gradient(45deg, #103252, #581e70);
  --background-alt: #1d222d;
  --foreground-alt: #0e1117;
  --invert: 1;
  --pop-up-background: rgba(14, 17, 23, 0.9);
}

* {
  font-family: 'Krub', sans-serif;
  color: var(--text);
}

.unicon {
  fill: var(--text);
}

h1
{
  text-align: left;
  font-weight: normal;
}

h2
{
  font-weight: normal;
  color: var(--textlight);
  text-align: left;
}

p{
  text-align: justify;
}

a{
  font-weight: 800;
  text-decoration: none;
}

a:hover{
    color: var(--accent);
}

section {
  max-width: 1200px;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 60px;
  min-height: calc(100vh - 170px);
}

.spacer {
  border: 1px solid var(--textlight);
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>