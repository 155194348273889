<template>
  <div class="resume-line">
    <div class="year"><h3>{{years}}</h3></div>
    <div class="line">
      <h3>
        {{degree}}<template v-if="university">, <i>{{university}}</i></template><template v-if="city">, {{city}}</template>
        <a v-if="fileUrl" :href="fileUrl" target="_blank"><unicon name="file-alt"/></a>
      </h3>
      <p class="details"><template v-if="classes">{{classes}}</template></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResumeLine',
  props: ['years', 'degree', 'university', 'city', 'classes', 'fileUrl']
}
</script>

<style scoped>
.resume-line {
  margin-bottom: 40px;
}

h3 {
  text-align: left;
  font-weight: normal;
  position: relative;
  font-size: smaller;
}

h3 a {
  position: absolute;
  right: 0;
}

p {
  margin-top: 5px;
}

p.details {
  display: none;
}

.year h3 {
  margin-bottom: 0;
}

.line {
  margin-left: 10px;
}

@media (min-width: 768px) {
  h3 {
    font-size: initial;
  }

  .resume-line {
    display: grid;
    grid-template-columns: 120px auto;
    grid-gap: 20px;
    margin: 20px 0;
  }

  .year {
    justify-self: end;
  }

  .year h3 {
    text-align: right;
  }

  p.details {
    display: initial;
  }

  .line {
    margin-left: 0;
  }
}
</style>