<template>
  <unicon name="sun" v-if="!dark" @click="toggleDark"/>
  <unicon name="moon" v-else @click="toggleDark"/>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'ThemeButton',
  props: ['options', 'value'],
  computed: {
    ...mapState(['dark'])
  },
  methods: {
    ...mapMutations(['toggleDark'])
  }
}
</script>

<style scoped>
.unicon {
  position: relative;
  top: 5px;
}
</style>